import {getGenres} from "../../Services/Actions/GenresAction";
import {en, fictionBookUrls, genresParsing, ru} from "../../Services/constants";
import React, {useCallback, useEffect, useMemo, useRef, useState} from "react";
import Loading from "../Loading/Loading";
import {t} from "i18next";
import './GenresList.scss';
import {adjstLang, checkCustomGenre, wrapToJson, wrapToJson1} from "../../Services/utils";
import {Trans} from "react-i18next";
import AddGenre from "../AddGenre/AddGenre";
import {Tooltip as ReactTooltip} from "react-tooltip";

const GenresList = (props) => {

    const myRef = useRef(null);

    const onClick = (code) => {
        if (code) {
            document.getElementById(code)?.classList.toggle("d-none");
            document.getElementById(code + '_exp')?.classList?.toggle("right");
            document.getElementById(code + '_exp')?.classList?.toggle("down");
        } else {
            if (document.getElementById('exp-all').classList.contains("right")) {
                Array.from(document.getElementsByClassName('child-wrapper')).forEach((child) => {
                    child.classList?.remove("d-none");
                });
                Array.from(document.getElementsByClassName('exp-arrow')).forEach((child) => {
                    child.classList?.remove("right");
                    child.classList?.add("down");
                });
            } else {
                Array.from(document.getElementsByClassName('child-wrapper')).forEach((child) => {
                    child.classList?.add("d-none");
                });
                Array.from(document.getElementsByClassName('exp-arrow')).forEach((child) => {
                    child.classList?.add("right");
                    child.classList?.remove("down");
                });
            }
        }
    }

    const addAlias = (code) => {
        myRef.current?.scrollIntoView();
        setData({...data, aliasTo: code});
    }

    const lang = props.lang ? props.lang : en;
    const newGenreName = props.newGenreName;

    const texts = t('genres.texts', {returnObjects: true});

    const initialData = useMemo(() => {
        return {
            loaded: false,
            withError: false,
            genres: [],
            parentToExpand: null,
            version: '',
            aliasTo: ''
        }
    }, []);

    const [data, setData] = useState(initialData);

    const legend = t('genres.legend', {returnObjects: true});

    const legendText = () => {
        return (<ul className="legend pl-2">
            {legend.map((item, index) => {
                return (<li key={index}>
                    {index === 2 && <Trans i18nKey={item} components={{data: data.version, bold: <strong/>}}/>}
                    {index !== 2 && <Trans i18nKey={item}/>}
                </li>);
            })}
        </ul>)
    };

    const formattedCode = (str) => {
        const words = str.split('_');
        return (<span>{words.map((item, index) => {
            return (
                <>
                    {item}
                    {index < words.length - 1 && <>_
                        <wbr/>
                    </>}
                </>
            )
        })}
        </span>)
    }

    const refreshGenres = useCallback(() => {

        getGenres().then(res => {
            const hContent = res['h'];
            const customMarkerIndex = hContent.indexOf(genresParsing.custom_marker);
            let treePrepared = res['tree'].substring(res['tree'].indexOf(genresParsing.startTag) +
                genresParsing.startTag.length, res['tree'].indexOf(genresParsing.endTag));
            treePrepared = treePrepared.trim().replaceAll('{genre::', '"').replaceAll('genre::', '"')
                .replaceAll(', {', '":[')
                .replaceAll(',{', '":[')
                .replaceAll(',}}', '"]')
                .replaceAll('}}', ']')
                .replaceAll('],', 'not_our_case')
                .replaceAll(',', '",')
                .replaceAll('not_our_case', '],');

            let conversionList = res['tree'].substring(res['tree'].indexOf(genresParsing.conversionStart) +
                genresParsing.conversionStart.length, res['tree'].indexOf(genresParsing.conversionEnd));
            conversionList = conversionList.replaceAll('core::genre::', '"').replaceAll('},', '"},').replaceAll('",', '":');

            try {
                const genresJson = wrapToJson(treePrepared);

                const conversionJSON = wrapToJson1(conversionList);

                const aliases = new Map();

                Array.from(conversionJSON).forEach(item => {
                    Object.keys(item).forEach(kkey => {
                        const val = item[kkey];
                        if (kkey !== val) {
                            let list = [];
                            if (aliases.has(val)) {
                                list = aliases.get(val);
                            }
                            list.push(kkey);
                            aliases.set(val, list);
                        }

                    });

                });

                const transJson = JSON.parse(res['trans']);
                const transJsonMain = JSON.parse(res['trans_main']);
                const projectMain = res['project'];
                const startIndex = projectMain.indexOf(genresParsing.marketingVersion) + genresParsing.marketingVersion.length + 3;
                const endIndex = projectMain.indexOf(';', startIndex);
                const version = projectMain.substring(startIndex, endIndex);

                const gnrs = [];

                let underApprove = 0;
                let total = 0;

                Object.keys(genresJson).forEach(key => {
                    const value = genresJson[key];

                    const translation = transJson['strings'][key]['localizations'][adjstLang(lang)]['stringUnit']['value'];

                    const translatedValues = value.map(function (vl) {

                        const custom = checkCustomGenre(hContent, customMarkerIndex, vl);

                        let newGenre = false;

                        if (custom) {
                            if (!transJsonMain['strings'][vl]) {
                                newGenre = true;
                                underApprove++;
                            }
                        }

                        total++;
                        return {
                            'code': vl,
                            'translation': transJson['strings'][vl]['localizations'][adjstLang(lang)]['stringUnit']['value'],
                            'custom': custom,
                            'new': newGenre,
                            'aliases': aliases.has(vl) ? aliases.get(vl).join(', ') : undefined
                        };
                    });

                    let newParentGenre = false;
                    const customParent = checkCustomGenre(hContent, customMarkerIndex, key);
                    if (customParent) {
                        if (!transJsonMain['strings'][key]) {
                            newParentGenre = true;
                            underApprove++;
                        }
                    }

                    gnrs.push({
                        'code': key,
                        'translation': translation,
                        'children': translatedValues,
                        'custom': customParent,
                        'new': newParentGenre,
                        'aliases': aliases.has(key) ? aliases.get(key).join(', ') : undefined
                    });
                    total++;
                });
                setData({genres: gnrs, loaded: true, total, underApprove, version});
            } catch (err) {
                console.log(err);
                setData({...initialData, withError: true});
            }
        }).catch(err0 => {
            console.log(err0);
            setData({...initialData, withError: true});
        });
    }, [initialData, lang]);

    useEffect(() => {
        refreshGenres();
    }, [refreshGenres]);

    const setParentToExpand = (el) => {
        setData({...data, loaded: false});
        refreshGenres();
        setData({...data, loaded: true});
        onClick(el.parent?.code);
    }

    const checkExistingGenre = (newGenre) => {
        let found = false;
        data.genres.forEach(genre => {
            if (genre.code === newGenre) {
                found = true;
            }
            genre.children.forEach(childGenre => {
                if (childGenre.code === newGenre) {
                    found = true;
                }
            })
        });
        return found;
    }

    return (
        <>
            {data.withError &&
            <div className="error global">{t('genres.connectingError')}</div>
            }
            {!data.withError &&
            <>
                {!data.loaded &&
                <Loading/>
                }
                {data.loaded &&
                <div className="genres" ref={myRef}>
                    <div className="row">
                        <div className="col-12">
                            <a href="#add">{t('genres.down')} ↓</a>
                            {texts.map((item, index) => {
                                if (Array.isArray(item)) {
                                    const joined = [];
                                    item.forEach((subItem, subIndex) => {
                                        joined.push(<li className="font-italic" key={subIndex}>{subItem}</li>);
                                    })
                                    return (<ul>{joined}</ul>);
                                } else {
                                    return (
                                        <>
                                            <p key={index} className={index === 3 ? 'font-italic' : ''}><Trans
                                                i18nKey={item} components={[<a
                                                href={lang === ru ? fictionBookUrls.ru : fictionBookUrls.en}> </a>]}/>
                                            </p>
                                            {index === 2 &&
                                            <AddGenre parentGenres={data.genres} lang={lang} aliasTo={data.aliasTo}
                                                      newGenreName={newGenreName}
                                                      onAdding={setParentToExpand}
                                                      checkExistingGenre={checkExistingGenre}/>}
                                        </>);
                                }
                            })}
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12 mt-2">
                            <h3>{t('genres.title')}</h3>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-5 col-sm-2"><b>{t('genres.code')}</b></div>
                        <div className="col-7 col-sm-10"><span className="clickable-wrapper"
                                                               onClick={() => onClick()}><b>{t('genres.name')}</b>
                            <span
                                id="exp-all"
                                className="exp-arrow right ml-1"/></span>
                        </div>
                    </div>
                    {data.genres.sort((a, b) => (a.translation > b.translation) ? 1 : ((b.translation > a.translation) ? -1 : 0)).map((genre) => (
                        <div className="parent-genre" key={genre.code}>
                            <div className={`row ${genre.custom ? 'custom' : ''} ${genre.new ? 'new' : ''}`}>
                                <div className="col-5 col-sm-2">
                                    <i>{formattedCode(genre.code)}</i>
                                    {genre.aliases &&
                                    <>
                                        <span data-tooltip-id={`alias_${genre.code}`} className="info small">i</span>
                                        <ReactTooltip
                                            id={`alias_${genre.code}`}
                                            place="bottom"
                                            variant="info"
                                            className="genre-info"
                                            content={
                                                <>{t('genres.aliases')} <b>{genre.aliases}</b></>
                                            }
                                        />
                                    </>
                                    }
                                </div>
                                <div className="col-7 col-sm-10">
                                    {genre.children.length === 0 && <i>{genre.translation}</i>}
                                    {genre.children.length > 0 &&
                                    <span className="clickable-wrapper"
                                          onClick={() => onClick(genre.code)}><i>{genre.translation}</i><span
                                        id={`${genre.code}_exp`} className="exp-arrow right ml-1"/></span>
                                    }
                                    <span data-tooltip-id={`add_${genre.code}`}
                                          className="add" onClick={() => addAlias(genre.code)}>+</span>
                                    <ReactTooltip
                                        id={`add_${genre.code}`}
                                        place="bottom"
                                        variant="info"
                                        className="genre-info"
                                        content={
                                            <>{t('genres.addNewAlias')}</>
                                        }
                                    />
                                </div>
                            </div>
                            {genre.children.length > 0 &&
                            <div id={genre.code} className="child-wrapper d-none">
                                {genre.children.sort((a, b) => (a.translation > b.translation) ? 1 : ((b.translation > a.translation) ? -1 : 0)).map((child) => (
                                    <div
                                        className={`row child-genre ${child.custom ? 'custom' : ''} ${child.new ? 'new' : ''}`}
                                        key={child.code}>
                                        <div className="col-5 col-sm-2 pl-4">
                                            {formattedCode(child.code)}
                                            {child.aliases && <>
                                                <span data-tooltip-id={`alias_${child.code}`}
                                                      className="info small">i</span>
                                                <ReactTooltip
                                                    id={`alias_${child.code}`}
                                                    place="bottom"
                                                    variant="info"
                                                    className="genre-info"
                                                    content={
                                                        <>{t('genres.aliases')} <b>{child.aliases}</b></>
                                                    }
                                                />
                                            </>}
                                        </div>
                                        <div className="col-7 col-sm-10 pl-4"><span>{child.translation}</span>
                                            <span data-tooltip-id={`add_${child.code}`}
                                                  className="add" onClick={() => addAlias(child.code)}>+</span>
                                            <ReactTooltip
                                                id={`add_${child.code}`}
                                                place="bottom"
                                                variant="info"
                                                className="genre-info"
                                                content={
                                                    <>{t('genres.addNewAlias')}</>
                                                }
                                            />
                                        </div>
                                    </div>
                                ))}
                            </div>
                            }
                        </div>
                    ))}
                    <div className="row summing mt-3 pb-3">
                        <div
                            className="col-12">{t('genres.total')}: <b>{data.total}</b><br/>{t('genres.underApprove')}: <b>{data.underApprove}</b>
                        </div>
                    </div>
                    <div className="px-3 px-sm-0">
                        <div className="row">
                            <div className="col-12 mt-2">
                                {legendText()}
                            </div>
                        </div>
                    </div>
                </div>
                }
            </>
            }
        </>
    )
}

export default GenresList